import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

var exports = {};
!function (e, t) {
  exports = t(_react);
}(exports, function (e) {
  return function (e) {
    var t = {};

    function r(l) {
      if (t[l]) return t[l].exports;
      var i = t[l] = {
        i: l,
        l: !1,
        exports: {}
      };
      return e[l].call(i.exports, i, i.exports, r), i.l = !0, i.exports;
    }

    return r.m = e, r.c = t, r.d = function (e, t, l) {
      r.o(e, t) || Object.defineProperty(e, t, {
        enumerable: !0,
        get: l
      });
    }, r.r = function (e) {
      "undefined" !== typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, r.t = function (e, t) {
      if (1 & t && (e = r(e)), 8 & t) return e;
      if (4 & t && "object" === typeof e && e && e.__esModule) return e;
      var l = Object.create(null);
      if (r.r(l), Object.defineProperty(l, "default", {
        enumerable: !0,
        value: e
      }), 2 & t && "string" != typeof e) for (var i in e) r.d(l, i, function (t) {
        return e[t];
      }.bind(null, i));
      return l;
    }, r.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return r.d(t, "a", t), t;
    }, r.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, r.p = "/", r(r.s = 1);
  }([function (t, r) {
    t.exports = e;
  }, function (e, t, r) {
    e.exports = r(2);
  }, function (e, t, r) {
    "use strict";

    function l(e, t, r) {
      return t in e ? Object.defineProperty(e, t, {
        value: r,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }) : e[t] = r, e;
    }

    function i(e, t) {
      var r = Object.keys(e);

      if (Object.getOwnPropertySymbols) {
        var l = Object.getOwnPropertySymbols(e);
        t && (l = l.filter(function (t) {
          return Object.getOwnPropertyDescriptor(e, t).enumerable;
        })), r.push.apply(r, l);
      }

      return r;
    }

    function a(e, t) {
      if (null == e) return {};

      var r,
          l,
          i = function (e, t) {
        if (null == e) return {};
        var r,
            l,
            i = {},
            a = Object.keys(e);

        for (l = 0; l < a.length; l++) r = a[l], t.indexOf(r) >= 0 || (i[r] = e[r]);

        return i;
      }(e, t);

      if (Object.getOwnPropertySymbols) {
        var a = Object.getOwnPropertySymbols(e);

        for (l = 0; l < a.length; l++) r = a[l], t.indexOf(r) >= 0 || Object.prototype.propertyIsEnumerable.call(e, r) && (i[r] = e[r]);
      }

      return i;
    }

    r.r(t);

    var n = r(0),
        c = r.n(n),
        h = function (e) {
      for (var t = 0, r = 0; r < e.length; r++) {
        t = (t << 5) - t + e.charCodeAt(r), t &= t;
      }

      return Math.abs(t);
    },
        o = function (e, t) {
      return Math.floor(e / Math.pow(10, t) % 10);
    },
        s = function (e, t) {
      return !(o(e, t) % 2);
    },
        m = function (e, t, r) {
      var l = e % t;
      return r && o(e, r) % 2 === 0 ? -l : l;
    },
        f = function (e, t, r) {
      return t[e % r];
    };

    var u = function (e) {
      var t = function (e, t) {
        var r = h(e),
            l = t && t.length;
        return Array.from({
          length: 4
        }, function (e, i) {
          return {
            color: f(r + i, t, l),
            translateX: m(r * (i + 1), 40 - (i + 17), 1),
            translateY: m(r * (i + 1), 40 - (i + 17), 2),
            rotate: m(r * (i + 1), 360),
            isSquare: s(r, 2)
          };
        });
      }(e.name, e.colors);

      return n.createElement("svg", {
        viewBox: "0 0 80 80",
        fill: "none",
        role: "img",
        xmlns: "http://www.w3.org/2000/svg",
        width: e.size,
        height: e.size
      }, e.title && n.createElement("title", null, e.name), n.createElement("mask", {
        id: "mask__bauhaus",
        maskUnits: "userSpaceOnUse",
        x: 0,
        y: 0,
        width: 80,
        height: 80
      }, n.createElement("rect", {
        width: 80,
        height: 80,
        rx: e.square ? void 0 : 160,
        fill: "#FFFFFF"
      })), n.createElement("g", {
        mask: "url(#mask__bauhaus)"
      }, n.createElement("rect", {
        width: 80,
        height: 80,
        fill: t[0].color
      }), n.createElement("rect", {
        x: 10,
        y: 30,
        width: 80,
        height: t[1].isSquare ? 80 : 10,
        fill: t[1].color,
        transform: "translate(" + t[1].translateX + " " + t[1].translateY + ") rotate(" + t[1].rotate + " 40 40)"
      }), n.createElement("circle", {
        cx: 40,
        cy: 40,
        fill: t[2].color,
        r: 16,
        transform: "translate(" + t[2].translateX + " " + t[2].translateY + ")"
      }), n.createElement("line", {
        x1: 0,
        y1: 40,
        x2: 80,
        y2: 40,
        strokeWidth: 2,
        stroke: t[3].color,
        transform: "translate(" + t[3].translateX + " " + t[3].translateY + ") rotate(" + t[3].rotate + " 40 40)"
      })));
    };

    var d = function (e) {
      var t = function (e, t) {
        var r = h(t),
            l = e && e.length,
            i = Array.from({
          length: 5
        }, function (t, i) {
          return f(r + i, e, l);
        }),
            a = [];
        return a[0] = i[0], a[1] = i[1], a[2] = i[1], a[3] = i[2], a[4] = i[2], a[5] = i[3], a[6] = i[3], a[7] = i[0], a[8] = i[4], a;
      }(e.colors, e.name);

      return c.a.createElement("svg", {
        viewBox: "0 0 90 90",
        fill: "none",
        role: "img",
        xmlns: "http://www.w3.org/2000/svg",
        width: e.size,
        height: e.size
      }, e.title && c.a.createElement("title", null, e.name), c.a.createElement("mask", {
        id: "mask__ring",
        maskUnits: "userSpaceOnUse",
        x: 0,
        y: 0,
        width: 90,
        height: 90
      }, c.a.createElement("rect", {
        width: 90,
        height: 90,
        rx: e.square ? void 0 : 180,
        fill: "#FFFFFF"
      })), c.a.createElement("g", {
        mask: "url(#mask__ring)"
      }, c.a.createElement("path", {
        d: "M0 0h90v45H0z",
        fill: t[0]
      }), c.a.createElement("path", {
        d: "M0 45h90v45H0z",
        fill: t[1]
      }), c.a.createElement("path", {
        d: "M83 45a38 38 0 00-76 0h76z",
        fill: t[2]
      }), c.a.createElement("path", {
        d: "M83 45a38 38 0 01-76 0h76z",
        fill: t[3]
      }), c.a.createElement("path", {
        d: "M77 45a32 32 0 10-64 0h64z",
        fill: t[4]
      }), c.a.createElement("path", {
        d: "M77 45a32 32 0 11-64 0h64z",
        fill: t[5]
      }), c.a.createElement("path", {
        d: "M71 45a26 26 0 00-52 0h52z",
        fill: t[6]
      }), c.a.createElement("path", {
        d: "M71 45a26 26 0 01-52 0h52z",
        fill: t[7]
      }), c.a.createElement("circle", {
        cx: 45,
        cy: 45,
        r: 23,
        fill: t[8]
      })));
    };

    var g = function (e) {
      var t = function (e, t) {
        var r = h(e),
            l = t && t.length;
        return Array.from({
          length: 64
        }, function (e, i) {
          return f(r % i, t, l);
        });
      }(e.name, e.colors);

      return n.createElement("svg", {
        viewBox: "0 0 80 80",
        fill: "none",
        role: "img",
        xmlns: "http://www.w3.org/2000/svg",
        width: e.size,
        height: e.size
      }, e.title && n.createElement("title", null, e.name), n.createElement("mask", {
        id: "mask__pixel",
        "mask-type": "alpha",
        maskUnits: "userSpaceOnUse",
        x: 0,
        y: 0,
        width: 80,
        height: 80
      }, n.createElement("rect", {
        width: 80,
        height: 80,
        rx: e.square ? void 0 : 160,
        fill: "#FFFFFF"
      })), n.createElement("g", {
        mask: "url(#mask__pixel)"
      }, n.createElement("rect", {
        width: 10,
        height: 10,
        fill: t[0]
      }), n.createElement("rect", {
        x: 20,
        width: 10,
        height: 10,
        fill: t[1]
      }), n.createElement("rect", {
        x: 40,
        width: 10,
        height: 10,
        fill: t[2]
      }), n.createElement("rect", {
        x: 60,
        width: 10,
        height: 10,
        fill: t[3]
      }), n.createElement("rect", {
        x: 10,
        width: 10,
        height: 10,
        fill: t[4]
      }), n.createElement("rect", {
        x: 30,
        width: 10,
        height: 10,
        fill: t[5]
      }), n.createElement("rect", {
        x: 50,
        width: 10,
        height: 10,
        fill: t[6]
      }), n.createElement("rect", {
        x: 70,
        width: 10,
        height: 10,
        fill: t[7]
      }), n.createElement("rect", {
        y: 10,
        width: 10,
        height: 10,
        fill: t[8]
      }), n.createElement("rect", {
        y: 20,
        width: 10,
        height: 10,
        fill: t[9]
      }), n.createElement("rect", {
        y: 30,
        width: 10,
        height: 10,
        fill: t[10]
      }), n.createElement("rect", {
        y: 40,
        width: 10,
        height: 10,
        fill: t[11]
      }), n.createElement("rect", {
        y: 50,
        width: 10,
        height: 10,
        fill: t[12]
      }), n.createElement("rect", {
        y: 60,
        width: 10,
        height: 10,
        fill: t[13]
      }), n.createElement("rect", {
        y: 70,
        width: 10,
        height: 10,
        fill: t[14]
      }), n.createElement("rect", {
        x: 20,
        y: 10,
        width: 10,
        height: 10,
        fill: t[15]
      }), n.createElement("rect", {
        x: 20,
        y: 20,
        width: 10,
        height: 10,
        fill: t[16]
      }), n.createElement("rect", {
        x: 20,
        y: 30,
        width: 10,
        height: 10,
        fill: t[17]
      }), n.createElement("rect", {
        x: 20,
        y: 40,
        width: 10,
        height: 10,
        fill: t[18]
      }), n.createElement("rect", {
        x: 20,
        y: 50,
        width: 10,
        height: 10,
        fill: t[19]
      }), n.createElement("rect", {
        x: 20,
        y: 60,
        width: 10,
        height: 10,
        fill: t[20]
      }), n.createElement("rect", {
        x: 20,
        y: 70,
        width: 10,
        height: 10,
        fill: t[21]
      }), n.createElement("rect", {
        x: 40,
        y: 10,
        width: 10,
        height: 10,
        fill: t[22]
      }), n.createElement("rect", {
        x: 40,
        y: 20,
        width: 10,
        height: 10,
        fill: t[23]
      }), n.createElement("rect", {
        x: 40,
        y: 30,
        width: 10,
        height: 10,
        fill: t[24]
      }), n.createElement("rect", {
        x: 40,
        y: 40,
        width: 10,
        height: 10,
        fill: t[25]
      }), n.createElement("rect", {
        x: 40,
        y: 50,
        width: 10,
        height: 10,
        fill: t[26]
      }), n.createElement("rect", {
        x: 40,
        y: 60,
        width: 10,
        height: 10,
        fill: t[27]
      }), n.createElement("rect", {
        x: 40,
        y: 70,
        width: 10,
        height: 10,
        fill: t[28]
      }), n.createElement("rect", {
        x: 60,
        y: 10,
        width: 10,
        height: 10,
        fill: t[29]
      }), n.createElement("rect", {
        x: 60,
        y: 20,
        width: 10,
        height: 10,
        fill: t[30]
      }), n.createElement("rect", {
        x: 60,
        y: 30,
        width: 10,
        height: 10,
        fill: t[31]
      }), n.createElement("rect", {
        x: 60,
        y: 40,
        width: 10,
        height: 10,
        fill: t[32]
      }), n.createElement("rect", {
        x: 60,
        y: 50,
        width: 10,
        height: 10,
        fill: t[33]
      }), n.createElement("rect", {
        x: 60,
        y: 60,
        width: 10,
        height: 10,
        fill: t[34]
      }), n.createElement("rect", {
        x: 60,
        y: 70,
        width: 10,
        height: 10,
        fill: t[35]
      }), n.createElement("rect", {
        x: 10,
        y: 10,
        width: 10,
        height: 10,
        fill: t[36]
      }), n.createElement("rect", {
        x: 10,
        y: 20,
        width: 10,
        height: 10,
        fill: t[37]
      }), n.createElement("rect", {
        x: 10,
        y: 30,
        width: 10,
        height: 10,
        fill: t[38]
      }), n.createElement("rect", {
        x: 10,
        y: 40,
        width: 10,
        height: 10,
        fill: t[39]
      }), n.createElement("rect", {
        x: 10,
        y: 50,
        width: 10,
        height: 10,
        fill: t[40]
      }), n.createElement("rect", {
        x: 10,
        y: 60,
        width: 10,
        height: 10,
        fill: t[41]
      }), n.createElement("rect", {
        x: 10,
        y: 70,
        width: 10,
        height: 10,
        fill: t[42]
      }), n.createElement("rect", {
        x: 30,
        y: 10,
        width: 10,
        height: 10,
        fill: t[43]
      }), n.createElement("rect", {
        x: 30,
        y: 20,
        width: 10,
        height: 10,
        fill: t[44]
      }), n.createElement("rect", {
        x: 30,
        y: 30,
        width: 10,
        height: 10,
        fill: t[45]
      }), n.createElement("rect", {
        x: 30,
        y: 40,
        width: 10,
        height: 10,
        fill: t[46]
      }), n.createElement("rect", {
        x: 30,
        y: 50,
        width: 10,
        height: 10,
        fill: t[47]
      }), n.createElement("rect", {
        x: 30,
        y: 60,
        width: 10,
        height: 10,
        fill: t[48]
      }), n.createElement("rect", {
        x: 30,
        y: 70,
        width: 10,
        height: 10,
        fill: t[49]
      }), n.createElement("rect", {
        x: 50,
        y: 10,
        width: 10,
        height: 10,
        fill: t[50]
      }), n.createElement("rect", {
        x: 50,
        y: 20,
        width: 10,
        height: 10,
        fill: t[51]
      }), n.createElement("rect", {
        x: 50,
        y: 30,
        width: 10,
        height: 10,
        fill: t[52]
      }), n.createElement("rect", {
        x: 50,
        y: 40,
        width: 10,
        height: 10,
        fill: t[53]
      }), n.createElement("rect", {
        x: 50,
        y: 50,
        width: 10,
        height: 10,
        fill: t[54]
      }), n.createElement("rect", {
        x: 50,
        y: 60,
        width: 10,
        height: 10,
        fill: t[55]
      }), n.createElement("rect", {
        x: 50,
        y: 70,
        width: 10,
        height: 10,
        fill: t[56]
      }), n.createElement("rect", {
        x: 70,
        y: 10,
        width: 10,
        height: 10,
        fill: t[57]
      }), n.createElement("rect", {
        x: 70,
        y: 20,
        width: 10,
        height: 10,
        fill: t[58]
      }), n.createElement("rect", {
        x: 70,
        y: 30,
        width: 10,
        height: 10,
        fill: t[59]
      }), n.createElement("rect", {
        x: 70,
        y: 40,
        width: 10,
        height: 10,
        fill: t[60]
      }), n.createElement("rect", {
        x: 70,
        y: 50,
        width: 10,
        height: 10,
        fill: t[61]
      }), n.createElement("rect", {
        x: 70,
        y: 60,
        width: 10,
        height: 10,
        fill: t[62]
      }), n.createElement("rect", {
        x: 70,
        y: 70,
        width: 10,
        height: 10,
        fill: t[63]
      })));
    };

    var w = function (e) {
      var t = function (e, t) {
        var r,
            l = h(e),
            i = t && t.length,
            a = f(l, t, i),
            n = m(l, 10, 1),
            c = n < 5 ? n + 4 : n,
            o = m(l, 10, 2),
            u = o < 5 ? o + 4 : o;
        return {
          wrapperColor: a,
          faceColor: (r = a, "#" === r.slice(0, 1) && (r = r.slice(1)), (299 * parseInt(r.substr(0, 2), 16) + 587 * parseInt(r.substr(2, 2), 16) + 114 * parseInt(r.substr(4, 2), 16)) / 1000 >= 128 ? "#000000" : "#FFFFFF"),
          backgroundColor: f(l + 13, t, i),
          wrapperTranslateX: c,
          wrapperTranslateY: u,
          wrapperRotate: m(l, 360),
          wrapperScale: 1 + m(l, 3) / 10,
          isMouthOpen: s(l, 2),
          isCircle: s(l, 1),
          eyeSpread: m(l, 5),
          mouthSpread: m(l, 3),
          faceRotate: m(l, 10, 3),
          faceTranslateX: c > 6 ? c / 2 : m(l, 8, 1),
          faceTranslateY: u > 6 ? u / 2 : m(l, 7, 2)
        };
      }(e.name, e.colors);

      return n.createElement("svg", {
        viewBox: "0 0 36 36",
        fill: "none",
        role: "img",
        xmlns: "http://www.w3.org/2000/svg",
        width: e.size,
        height: e.size
      }, e.title && n.createElement("title", null, e.name), n.createElement("mask", {
        id: "mask__beam",
        maskUnits: "userSpaceOnUse",
        x: 0,
        y: 0,
        width: 36,
        height: 36
      }, n.createElement("rect", {
        width: 36,
        height: 36,
        rx: e.square ? void 0 : 72,
        fill: "#FFFFFF"
      })), n.createElement("g", {
        mask: "url(#mask__beam)"
      }, n.createElement("rect", {
        width: 36,
        height: 36,
        fill: t.backgroundColor
      }), n.createElement("rect", {
        x: "0",
        y: "0",
        width: 36,
        height: 36,
        transform: "translate(" + t.wrapperTranslateX + " " + t.wrapperTranslateY + ") rotate(" + t.wrapperRotate + " 18 18) scale(" + t.wrapperScale + ")",
        fill: t.wrapperColor,
        rx: t.isCircle ? 36 : 6
      }), n.createElement("g", {
        transform: "translate(" + t.faceTranslateX + " " + t.faceTranslateY + ") rotate(" + t.faceRotate + " 18 18)"
      }, t.isMouthOpen ? n.createElement("path", {
        d: "M15 " + (19 + t.mouthSpread) + "c2 1 4 1 6 0",
        stroke: t.faceColor,
        fill: "none",
        strokeLinecap: "round"
      }) : n.createElement("path", {
        d: "M13," + (19 + t.mouthSpread) + " a1,0.75 0 0,0 10,0",
        fill: t.faceColor
      }), n.createElement("rect", {
        x: 14 - t.eyeSpread,
        y: 14,
        width: 1.5,
        height: 2,
        rx: 1,
        stroke: "none",
        fill: t.faceColor
      }), n.createElement("rect", {
        x: 20 + t.eyeSpread,
        y: 14,
        width: 1.5,
        height: 2,
        rx: 1,
        stroke: "none",
        fill: t.faceColor
      }))));
    };

    var E = function (e) {
      var t = function (e, t) {
        var r = h(e),
            l = t && t.length;
        return Array.from({
          length: 4
        }, function (e, i) {
          return f(r + i, t, l);
        });
      }(e.name, e.colors),
          r = e.name.replace(/\s/g, "");

      return n.createElement("svg", {
        viewBox: "0 0 80 80",
        fill: "none",
        role: "img",
        xmlns: "http://www.w3.org/2000/svg",
        width: e.size,
        height: e.size
      }, e.title && n.createElement("title", null, e.name), n.createElement("mask", {
        id: "mask__sunset",
        maskUnits: "userSpaceOnUse",
        x: 0,
        y: 0,
        width: 80,
        height: 80
      }, n.createElement("rect", {
        width: 80,
        height: 80,
        rx: e.square ? void 0 : 160,
        fill: "#FFFFFF"
      })), n.createElement("g", {
        mask: "url(#mask__sunset)"
      }, n.createElement("path", {
        fill: "url(#gradient_paint0_linear_" + r + ")",
        d: "M0 0h80v40H0z"
      }), n.createElement("path", {
        fill: "url(#gradient_paint1_linear_" + r + ")",
        d: "M0 40h80v40H0z"
      })), n.createElement("defs", null, n.createElement("linearGradient", {
        id: "gradient_paint0_linear_" + r,
        x1: 40,
        y1: 0,
        x2: 40,
        y2: 40,
        gradientUnits: "userSpaceOnUse"
      }, n.createElement("stop", {
        stopColor: t[0]
      }), n.createElement("stop", {
        offset: 1,
        stopColor: t[1]
      })), n.createElement("linearGradient", {
        id: "gradient_paint1_linear_" + r,
        x1: 40,
        y1: 40,
        x2: 40,
        y2: 80,
        gradientUnits: "userSpaceOnUse"
      }, n.createElement("stop", {
        stopColor: t[2]
      }), n.createElement("stop", {
        offset: 1,
        stopColor: t[3]
      }))));
    };

    var p = function (e) {
      var t = function (e, t) {
        var r = h(e),
            l = t && t.length;
        return Array.from({
          length: 3
        }, function (e, i) {
          return {
            color: f(r + i, t, l),
            translateX: m(r * (i + 1), 8, 1),
            translateY: m(r * (i + 1), 8, 2),
            scale: 1.2 + m(r * (i + 1), 4) / 10,
            rotate: m(r * (i + 1), 360, 1)
          };
        });
      }(e.name, e.colors);

      return n.createElement("svg", {
        viewBox: "0 0 80 80",
        fill: "none",
        role: "img",
        xmlns: "http://www.w3.org/2000/svg",
        width: e.size,
        height: e.size
      }, e.title && n.createElement("title", null, e.name), n.createElement("mask", {
        id: "mask__marble",
        maskUnits: "userSpaceOnUse",
        x: 0,
        y: 0,
        width: 80,
        height: 80
      }, n.createElement("rect", {
        width: 80,
        height: 80,
        rx: e.square ? void 0 : 160,
        fill: "#FFFFFF"
      })), n.createElement("g", {
        mask: "url(#mask__marble)"
      }, n.createElement("rect", {
        width: 80,
        height: 80,
        fill: t[0].color
      }), n.createElement("path", {
        filter: "url(#prefix__filter0_f)",
        d: "M32.414 59.35L50.376 70.5H72.5v-71H33.728L26.5 13.381l19.057 27.08L32.414 59.35z",
        fill: t[1].color,
        transform: "translate(" + t[1].translateX + " " + t[1].translateY + ") rotate(" + t[1].rotate + " 40 40) scale(" + t[2].scale + ")"
      }), n.createElement("path", {
        filter: "url(#prefix__filter0_f)",
        style: {
          mixBlendMode: "overlay"
        },
        d: "M22.216 24L0 46.75l14.108 38.129L78 86l-3.081-59.276-22.378 4.005 12.972 20.186-23.35 27.395L22.215 24z",
        fill: t[2].color,
        transform: "translate(" + t[2].translateX + " " + t[2].translateY + ") rotate(" + t[2].rotate + " 40 40) scale(" + t[2].scale + ")"
      })), n.createElement("defs", null, n.createElement("filter", {
        id: "prefix__filter0_f",
        filterUnits: "userSpaceOnUse",
        colorInterpolationFilters: "sRGB"
      }, n.createElement("feFlood", {
        floodOpacity: 0,
        result: "BackgroundImageFix"
      }), n.createElement("feBlend", {
        in: "SourceGraphic",
        in2: "BackgroundImageFix",
        result: "shape"
      }), n.createElement("feGaussianBlur", {
        stdDeviation: 7,
        result: "effect1_foregroundBlur"
      }))));
    },
        y = ["pixel", "bauhaus", "ring", "beam", "sunset", "marble"],
        x = {
      geometric: "beam",
      abstract: "bauhaus"
    },
        v = function (e) {
      var t = e.variant,
          r = void 0 === t ? "marble" : t,
          n = e.colors,
          h = void 0 === n ? ["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"] : n,
          o = e.name,
          s = void 0 === o ? "Clara Barton" : o,
          m = e.square,
          f = void 0 !== m && m,
          v = e.title,
          b = void 0 !== v && v,
          _ = e.size,
          F = function (e) {
        for (var t = 1; t < arguments.length; t++) {
          var r = null != arguments[t] ? arguments[t] : {};
          t % 2 ? i(Object(r), !0).forEach(function (t) {
            l(e, t, r[t]);
          }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(r)) : i(Object(r)).forEach(function (t) {
            Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(r, t));
          });
        }

        return e;
      }({
        colors: h,
        name: s,
        title: b,
        size: void 0 === _ ? 40 : _,
        square: f
      }, a(e, ["variant", "colors", "name", "square", "title", "size"]));

      return {
        pixel: c.a.createElement(g, F),
        bauhaus: c.a.createElement(u, F),
        ring: c.a.createElement(d, F),
        beam: c.a.createElement(w, F),
        sunset: c.a.createElement(E, F),
        marble: c.a.createElement(p, F)
      }[Object.keys(x).includes(r) ? x[r] : y.includes(r) ? r : "marble"];
    };

    t.default = v;
  }]);
});
export default exports;